<template>
 <div id="page-container" class="pb-5">
  <div class="content-wrap">
   <PbxDashboard
    v-model:selector="periodSelector"
    @action="setPeriod()"
    @setSelector="setSelector = $event"
    @all-selected="allSelected($event)"
    @button-selector="(...args) => getSelectedButton(...args)"
    @timeSelector="setPeriod()"
    :buttonSelected="this.buttonSelected"
    :extensions="extensions"
    :dataColumnChartQuarterHourlyCons="
     selected_answered_type === 'day'
      ? dataColumnChartCons
      : selected_answered_type === 'week'
      ? dataColumnChartConsWeek
      : selected_answered_type === 'weekNumber'
      ? dataColumnChartConsWeekNumber
      : selected_answered_type === 'hour'
      ? isHourlyChart
        ? dataColumnChartHourlyCons
        : dataColumnChartQuarterHourlyCons
      : dataColumnChartConsMonth
    "
    :dataLineChartQuarterHourlyCons="
     selected_answered_type === 'day'
      ? dataLineChartCons
      : selected_answered_type === 'week'
      ? dataLineChartConsWeek
      : selected_answered_type === 'weekNumber'
      ? dataLineChartConsWeekNumber
      : selected_answered_type === 'hour'
      ? isHourlyChart
        ? dataLineChartHourlyCons
        : dataLineChartQuarterHourlyCons
      : dataLineChartConsMonth
    "
    :dataColumnChartQuarterHourly="
     is_filtered
      ? selected_answered_type === 'day'
        ? filterNoData(dataColumnChart)
        : selected_answered_type === 'week'
        ? filterNoData(dataColumnChartWeek)
        : selected_answered_type === 'weekNumber'
        ? filterNoData(dataColumnChartWeekNumber)
        : selected_answered_type === 'hour'
        ? isHourlyChart
          ? filterNoData(dataColumnChartHourly)
          : filterNoData(dataColumnChartQuarterHourly)
        : filterNoData(dataColumnChartMonth)
      : selected_answered_type === 'day'
      ? dataColumnChart
      : selected_answered_type === 'week'
      ? dataColumnChartWeek
      : selected_answered_type === 'weekNumber'
      ? dataColumnChartWeekNumber
      : selected_answered_type === 'hour'
      ? isHourlyChart
        ? dataColumnChartHourly
        : dataColumnChartQuarterHourly
      : dataColumnChartMonth
    "
    :dataLineChartQuarterHourly="
     is_filtered
      ? selected_answered_type === 'day'
        ? filterNoData(dataLineChart)
        : selected_answered_type === 'week'
        ? filterNoData(dataLineChartWeek)
        : selected_answered_type === 'weekNumber'
        ? filterNoData(dataLineChartWeekNumber)
        : selected_answered_type === 'hour'
        ? isHourlyChart
          ? filterNoData(dataLineChartHourly)
          : filterNoData(dataLineChartQuarterHourly)
        : filterNoData(dataLineChartMonth)
      : selected_answered_type === 'day'
      ? dataLineChart
      : selected_answered_type === 'week'
      ? dataLineChartWeek
      : selected_answered_type === 'weekNumber'
      ? dataLineChartWeekNumber
      : selected_answered_type === 'hour'
      ? isHourlyChart
        ? dataLineChartHourly
        : dataLineChartQuarterHourly
      : dataLineChartMonth
    "
    :dataColumnChartHourly="
     is_daily ? dataColumnChartCons : dataColumnChartHourly
    "
    :periodSelector="this.periodSelector"
    :timeSelected="this.timeSelected"
    :textMonth="this.textMonth"
    :urlType="urlType"
    :queryElement="queryElement"
    @concatenate="concatenate = $event"
    @setPeriod="(...args) => setPeriod(...args)"
    @is_filtered="is_filtered = $event"
    @selected_answered_type="selected_answered_type = $event"
    :startDate="startDate"
    :endDate="endDate"
    @activeBanner="$emit('activeBanner')"
    @successMsg="$emit('successMsg', $event)"
    @success="$emit('success', $event)"
    @setLoading="$emit('setLoading', $event)"
    @setHourlyChart="isHourlyChart = $event"
    :isHourlyChart="isHourlyChart"
    :pbxElements="pbxElements"
   />
  </div>
  <!-- <div v-show="extensions.length === 0"><EmptyStateDashboard /></div> -->
 </div>
 <Loading
  v-model:active="isLoading"
  :can-cancel="true"
  :on-cancel="onCancel"
  :is-full-page="fullPage"
 />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import PbxDashboard from "../../components/PBX/PbxDashboard.vue";
import EmptyStateDashboard from "../../components/PBX/EmptyStateDashboard.vue";

const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri"];
const daysOfWeekFrench = ["lundi", "mardi", "mercredi", "jeudi", "vendredi"];

export default {
 props: ["presentationKey"],
 components: {
  EmptyStateDashboard,
  Loading,
  PbxDashboard,
 },
 data() {
  return {
   isHourlyChart: true,
   daysOfWeek,
   daysOfWeekFrench,
   selected_answered_type: "hour",
   is_filtered: true,
   is_daily: true,
   concatenate: false,
   queryElement: [],
   setSelector: undefined,
   isQueryParam: false,
   isLoading: false,
   fullPage: true,
   periodSelector: undefined,
   dataColumnChartQuarterHourly: [],
   dataColumnChartQuarterHourlyCons: [],
   dataColumnChartHourly: [],
   dataColumnChartHourlyCategorie: [],
   dataColumnChartHourlyCons: [],
   dataColumnChart: [],
   dataColumnChartWeek: [],
   dataColumnChartWeekNumber: [],
   dataColumnChartMonth: [],
   dataColumnChartCons: [],
   dataColumnChartConsWeek: [],
   dataColumnChartConsWeekNumber: [],
   dataColumnChartConsMonth: [],

   dataLineChartQuarterHourly: [],
   dataLineChartQuarterHourlyCons: [],
   dataLineChartHourly: [],
   dataLineChartHourlyCategorie: [],
   dataLineChartHourlyCons: [],
   dataLineChart: [],
   dataLineChartWeek: [],
   dataLineChartWeekNumber: [],
   dataLineChartMonth: [],
   dataLineChartCons: [],
   dataLineChartConsWeek: [],
   dataLineChartConsWeekNumber: [],
   dataLineChartConsMonth: [],

   extensions: [],
   timeSelected: [],
   isAllSelected: "",
   buttonSelected: "month",
   textMonth: "",
   data: undefined,
   startDate: null,
   endDate: null,
   pbxMap: null,
   dns: null,
   pbxElements: null,
   urlType: null,
  };
 },
 methods: {
  async setPeriod(...args) {
   this.$emit("setLoading", true);
   this.dataColumnChartQuarterHourly = [];
   this.dataColumnChartQuarterHourlyCons = [];
   this.dataColumnChartHourly = [];
   this.dataColumnChartHourlyCons = [];
   this.dataColumnChart = [];
   this.dataColumnChartWeek = [];
   this.dataColumnChartWeekNumber = [];
   this.dataColumnChartMonth = [];
   this.dataColumnChartCons = [];
   this.dataColumnChartConsWeek = [];
   this.dataColumnChartConsWeekNumber = [];
   this.dataColumnChartConsMonth = [];

   this.dataLineChartQuarterHourly = [];
   this.dataLineChartQuarterHourlyCons = [];
   this.dataLineChartHourly = [];
   this.dataLineChartHourlyCons = [];
   this.dataLineChart = [];
   this.dataLineChartWeek = [];
   this.dataLineChartWeekNumber = [];
   this.dataLineChartMonth = [];
   this.dataLineChartCons = [];
   this.dataLineChartConsWeek = [];
   this.dataLineChartConsWeekNumber = [];
   this.dataLineChartConsMonth = [];

   const data = await this.getKpiData();

   switch (this.selected_answered_type) {
    case "hour":
     //  this.getDataFromElementForColumn();
     this.setHourChartData(data?.hour, data?.quarter);
    case "day":
     //  this.getDataFromElementForColumnDaily();
     this.setDailyChartData(data?.day);
    case "week":
     //  this.getDataFromElementForColumnWeek();
     this.setWeeklyChartData(data?.weekday);
    case "weekNumber":
     //  this.getDataFromElementForColumnWeekNumber();
     this.setWeeklyChartByWeekNumber(data?.week);
    case "month":
     //  this.getDataFromElementForColumnMonth();
     this.setMonthlyChartData(data?.month);
    default:
     return "";
   }
   //  this.is_daily
   //   ? this.getDataFromElementForColumnDaily()
   //   : this.getDataFromElementForColumn();
  },
  getButtonSelectedFromQueryPeriod() {
   let period = undefined;
   if (this.$route.query.period) {
    period = this.$route.query.period;
   }
   if (period) {
    if (period.length == 8) {
     this.buttonSelected = "day";
    } else if (period.length == 6) {
     this.buttonSelected = "month";
    } else {
     this.buttonSelected = "week";
    }
   }
  },
  async getKpiData(alias) {
   try {
    const res = await axios.get(
     `https://xp-stats-201.bluerock.tel/api/v1.2/reports/${this.presentationKey}`
    );
    console.log(res.data);
    this.pbxMap = res.data?.map ? res.data.map : null;

    let parameters = res.data.parameters;
    this.dns = parameters?.dns?.split(",");
    this.pbxElements = parameters?.dns?.split(",");
    this.startDate = parameters?.start;
    this.endDate = parameters?.end;
    this.urlType =
     parameters.element_type == 4
      ? "queue"
      : parameters.element_type == 0
      ? "extension"
      : "did";

    this.$store.dispatch("pbxElementType", this.urlType);
    this.periodSelector = this.formatPeriod(parameters.start, parameters.end);

    this.$store.dispatch("hostName", parameters.host_name);

    if (res.data?.pbx_table_config) {
     this.$store.dispatch("pbx3cxTableConfig", res.data?.pbx_table_config);
    }
    this.$emit("setLoading", false);
    return res.data?.data;
   } catch (error) {
    this.$emit("setLoading", false);
    // this.$emit("error404", error.response);
    this.errorHandling(error);
   }
  },
  setTimeSelected(event) {
   this.timeSelected = event;
  },
  allSelected(val) {
   this.isAllSelected = val;
  },
  getSelectedButton(...args) {
   this.buttonSelected = args[0];
   this.textMonth = args[1];
  },
  async setHourChartData(data, quarterData) {
   this.setQuarterHourChartData(quarterData);
   //  const data = await this.getKpiData("hour");
   const startHour = parseInt(this.pbxTimeSelected[0].slice(0, 2));
   const endHour = parseInt(this.pbxTimeSelected[1].slice(0, 2)) - 1;
   const allHours = [];

   // Create a list of hours from startHour to endHour
   for (let i = startHour; i <= endHour; i++) {
    allHours.push(`${String(i).padStart(2, "0")}`); // Only the hour part (08, 09, ... 18)
   }

   // Create an array to hold the formatted output
   const formattedOutput = [];
   const formattedOutputWaiting = [];
   const formattedOutputWaitingToConsolidate = [];

   // Iterate over each dn entry
   if (data?.length) {
    data.forEach(item => {
     const dn = item.dn;
     const details = item.details;

     let displayName = `${dn} - ${
      this.urlType === "queue"
       ? this.pbxMap?.call_queues[dn]?.name
       : this.urlType === "extension"
       ? this.pbxMap?.extensions[dn]
       : dn
     }`;

     // Initialize the data structure for the current dn
     const answeredCallsData = Array(allHours.length).fill(0);
     const inboundCallsData = Array(allHours.length).fill(0);
     const waitingTimeData = Array(allHours.length).fill(0);

     // Populate data arrays for answered and inbound calls
     allHours.forEach((hour, index) => {
      let fullHour = hour + ":00";
      const hourData = details[fullHour];

      if (hourData) {
       answeredCallsData[index] = Number(hourData.answered_calls);
       inboundCallsData[index] = Number(hourData.inbound_calls);
       waitingTimeData[index] = Number(hourData.waiting_time);
      }
     });

     // Construct the entry for the current dn
     formattedOutput.push({
      name: `${displayName}`,
      labels: allHours,
      data: [
       {
        name: "Répondus",
        data: answeredCallsData,
        type: "column",
       },
       {
        name: "Non répondus",
        data: inboundCallsData.map(
         (inbound, index) => inbound - answeredCallsData[index]
        ),
        type: "column",
       },
      ],
     });

     formattedOutputWaiting.push({
      name: `${displayName}`,
      labels: allHours,
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingTimeData.map((waiting, index) =>
         answeredCallsData[index] ? waiting / answeredCallsData[index] : 0
        ),
       },
      ],
     });

     formattedOutputWaitingToConsolidate.push({
      name: `${displayName}`,
      labels: allHours,
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingTimeData.map((waiting, index) => waiting),
       },
      ],
     });
    });
    this.dataColumnChartHourly = this.addMissingPbxElements(formattedOutput);
    this.dataColumnChartHourlyCons = this.consolidateChartData(formattedOutput);

    this.dataLineChartHourly = this.addMissingPbxElementsLine(
     formattedOutputWaiting
    );
    this.dataLineChartHourlyCons = this.consolidateLineChartData(
     formattedOutputWaitingToConsolidate,
     this.consolidateChartData(formattedOutput)
    );
   }
  },
  async setQuarterHourChartData(data) {
   const startHour = parseInt(this.pbxTimeSelected[0].slice(0, 2));
   const endHour = parseInt(this.pbxTimeSelected[1].slice(0, 2)) - 1;
   const allHours = [];

   // Create a list of quarter-hour time slots from startHour to endHour
   for (let hour = startHour; hour <= endHour; hour++) {
    for (let quarter = 0; quarter < 4; quarter++) {
     allHours.push(
      `${String(hour).padStart(2, "0")}${String(quarter * 15).padStart(2, "0")}`
     );
    }
   }

   // Create an array to hold the formatted output
   const formattedOutput = [];
   const formattedOutputWaiting = [];
   const formattedOutputWaitingToConsolidate = [];

   // Iterate over each dn entry
   if (data?.length && this.pbxElements?.length) {
    data.forEach(item => {
     const dn = item.dn;
     const details = item.details;

     let displayName = `${dn} - ${
      this.urlType === "queue"
       ? this.pbxMap?.call_queues[dn]?.name
       : this.urlType === "extension"
       ? this.pbxMap?.extensions[dn]
       : dn
     }`;

     // Initialize the data structure for the current dn
     const answeredCallsData = Array(allHours.length).fill(0);
     const inboundCallsData = Array(allHours.length).fill(0);
     const waitingTimeData = Array(allHours.length).fill(0);

     // Populate data arrays for answered and inbound calls
     allHours.forEach((hour, index) => {
      let formatedHour = hour.slice(0, 2) + ":" + hour.slice(-2);
      const hourData = details[`${formatedHour}`];

      if (hourData) {
       answeredCallsData[index] = Number(hourData.answered_calls);
       inboundCallsData[index] = Number(hourData.inbound_calls);
       waitingTimeData[index] = Number(hourData.waiting_time);
      }
     });

     // Construct the entry for the current dn
     formattedOutput.push({
      name: `${displayName}`,
      labels: allHours,
      data: [
       {
        name: "Répondus",
        data: answeredCallsData,
        type: "column",
       },
       {
        name: "Non répondus",
        data: inboundCallsData.map(
         (inbound, index) => inbound - answeredCallsData[index]
        ),
        type: "column",
       },
       //  {
       //   name: "Waiting",
       //   data: waitingTimeData.map((waiting, index) =>
       //   answeredCallsData[index] ? (waiting / answeredCallsData[index]).toFixed(0) : 0
       //   ),
       //   type: "line",
       //  },
      ],
     });
     formattedOutputWaiting.push({
      name: `${displayName}`,
      labels: allHours,
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingTimeData.map((waiting, index) =>
         answeredCallsData[index] ? waiting / answeredCallsData[index] : 0
        ),
       },
      ],
     });

     formattedOutputWaitingToConsolidate.push({
      name: `${displayName}`,
      labels: allHours,
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingTimeData.map((waiting, index) => waiting),
       },
      ],
     });
    });
    this.dataColumnChartQuarterHourly = this.addMissingPbxElements(
     formattedOutput
    );
    this.dataColumnChartQuarterHourlyCons = this.consolidateChartData(
     formattedOutput
    );
    this.dataLineChartQuarterHourly = this.addMissingPbxElementsLine(
     formattedOutputWaiting
    );
    this.dataLineChartQuarterHourlyCons = this.consolidateLineChartData(
     formattedOutputWaitingToConsolidate,
     this.consolidateChartData(formattedOutput)
    );
   }
  },
  async setDailyChartData(data) {
   const allDays = this.generateDateRange(this.startDate, this.endDate); // Generate all days in the range

   const formattedOutput = [];
   const formattedOutputWaiting = [];
   const formattedOutputWaitingToConsolidate = [];

   // Iterate over each item in data
   if (data?.length) {
    data.forEach(item => {
     const dn = item.dn;
     const details = item.details;

     let displayName = `${dn} - ${
      this.urlType === "queue"
       ? this.pbxMap?.call_queues[dn]?.name
       : this.urlType === "extension"
       ? this.pbxMap?.extensions[dn]
       : dn
     }`;

     const answeredCallsData = [];
     const inboundCallsData = [];
     const waitingCallsData = [];

     // Loop through all days and build the data array
     allDays.forEach(day => {
      const dayStr = day.format("YYYY-MM-DD"); // Format day as string (e.g., 2024-08-01)
      const dayData = details[dayStr]; // Check if there is data for this day

      if (dayData) {
       answeredCallsData.push(Number(dayData.answered_calls));
       inboundCallsData.push(Number(dayData.inbound_calls));
       waitingCallsData.push(Number(dayData.waiting_time));
      } else {
       // If no data for the day, add 0s
       answeredCallsData.push(0);
       inboundCallsData.push(0);
       waitingCallsData.push(0);
      }
     });

     // Construct the entry for current dn (handling non répondus as inbound - answered)
     formattedOutput.push({
      name: `${displayName}`,
      labels: allDays.map(day => day.toISOString()), // Format as ISO date strings
      data: [
       {
        name: "Répondus",
        data: answeredCallsData,
       },
       {
        name: "Non répondus",
        data: inboundCallsData.map(
         (inbound, index) => inbound - answeredCallsData[index]
        ),
       },
      ],
     });

     formattedOutputWaiting.push({
      name: `${displayName}`,
      labels: allDays.map(day => day.toISOString()),
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingCallsData.map((waiting, index) =>
         answeredCallsData[index] ? waiting / answeredCallsData[index] : 0
        ),
       },
      ],
     });

     formattedOutputWaitingToConsolidate.push({
      name: `${displayName}`,
      labels: allDays.map(day => day.toISOString()),
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingCallsData.map((waiting, index) => waiting),
       },
      ],
     });
    });

    // Assign the output to the dataColumnChart variable
    this.dataColumnChart = this.addMissingPbxElements(formattedOutput);
    this.dataColumnChartCons = this.consolidateChartData(formattedOutput);
    this.dataLineChart = this.addMissingPbxElementsLine(formattedOutputWaiting);
    this.dataLineChartCons = this.consolidateLineChartData(
     formattedOutputWaitingToConsolidate,
     this.consolidateChartData(formattedOutput)
    );
   }
  },
  async setWeeklyChartData(data) {
   const formattedOutput = [];
   const formattedOutputWaiting = [];
   const formattedOutputWaitingToConsolidate = [];

   // Iterate over each item in data
   if (data?.length) {
    data.forEach(item => {
     const dn = item.dn;
     const details = item.details;

     let displayName = `${dn} - ${
      this.urlType === "queue"
       ? this.pbxMap?.call_queues[dn]?.name
       : this.urlType === "extension"
       ? this.pbxMap?.extensions[dn]
       : dn
     }`;

     const answeredCallsData = Array(5).fill(0);
     const inboundCallsData = Array(5).fill(0);
     const waitingCallsData = Array(5).fill(0);

     // Loop through details and fill the data arrays based on weekday_number (1=Mon, 2=Tue, etc.)
     Object.keys(details).forEach(key => {
      const dayData = details[key];
      const weekdayIndex = dayData.weekday_number - 1; // Adjust to 0-based index (1=Lun -> 0)

      // Update answered calls and inbound calls for this weekday
      answeredCallsData[weekdayIndex] = Number(dayData.answered_calls);
      inboundCallsData[weekdayIndex] = Number(dayData.inbound_calls);
      waitingCallsData[weekdayIndex] = Number(dayData.waiting_time);
     });

     // Construct the entry for current dn (handling non répondus as inbound - answered)
     formattedOutput.push({
      name: `${displayName}`,
      labels: this.daysOfWeekFrench, // Use French day names as labels
      data: [
       {
        name: "Répondus",
        data: answeredCallsData,
       },
       {
        name: "Non répondus",
        data: inboundCallsData.map(
         (inbound, index) => inbound - answeredCallsData[index]
        ),
       },
      ],
     });

     formattedOutputWaiting.push({
      name: `${displayName}`,
      labels: this.daysOfWeekFrench, // Use French day names as labels
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingCallsData.map((waiting, index) =>
         answeredCallsData[index] ? waiting / answeredCallsData[index] : 0
        ),
       },
      ],
     });

     formattedOutputWaitingToConsolidate.push({
      name: `${displayName}`,
      labels: this.daysOfWeekFrench, // Use French day names as labels
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingCallsData.map((waiting, index) => waiting),
       },
      ],
     });
    });

    // Assign the output to the dataColumnChartWeek variable
    this.dataColumnChartWeek = this.addMissingPbxElements(formattedOutput);
    this.dataColumnChartConsWeek = this.consolidateChartData(formattedOutput);

    this.dataLineChartWeek = this.addMissingPbxElementsLine(
     formattedOutputWaiting
    );
    this.dataLineChartConsWeek = this.consolidateLineChartData(
     formattedOutputWaitingToConsolidate,
     this.consolidateChartData(formattedOutput)
    );
   }
  },
  async setWeeklyChartByWeekNumber(data) {
   const formattedOutput = [];
   const formattedOutputWaiting = [];
   const formattedOutputWaitingToConsolidate = [];

   // Iterate over each item in data
   if (data?.length) {
    data.forEach(item => {
     const dn = item.dn;
     const details = item.details;

     let displayName = `${dn} - ${
      this.urlType === "queue"
       ? this.pbxMap?.call_queues[dn]?.name
       : this.urlType === "extension"
       ? this.pbxMap?.extensions[dn]
       : dn
     }`;

     const weekLabels = [];
     const answeredCallsData = [];
     const nonAnsweredCallsData = [];
     const waitingCallsData = [];
     const waitingCallsDataToConsolidate = [];

     // Loop through details and fill the arrays for week labels, answered and non answered calls
     Object.keys(details).forEach(key => {
      const weekData = details[key];
      const weekNumber = weekData.week_number; // Week number (e.g., 30, 31, etc.)

      weekLabels.push(String(weekNumber)); // Add week number to labels (converted to string)
      answeredCallsData.push(Number(weekData.answered_calls)); // Add Répondus to the data
      waitingCallsData.push(
       Number(weekData.answered_calls)
        ? Number(weekData.waiting_time) / Number(weekData.answered_calls)
        : 0
      );
      waitingCallsDataToConsolidate.push(Number(weekData.waiting_time));
      nonAnsweredCallsData.push(
       Number(weekData.inbound_calls) - Number(weekData.answered_calls)
      ); // Calculate Non répondus
     });

     // Construct the entry for current dn
     formattedOutput.push({
      name: `${displayName}`,
      labels: weekLabels, // Week numbers as labels
      data: [
       {
        name: "Répondus",
        data: answeredCallsData,
       },
       {
        name: "Non répondus",
        data: nonAnsweredCallsData,
       },
      ],
     });

     formattedOutputWaiting.push({
      name: `${displayName}`,
      labels: weekLabels, // Week numbers as labels
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingCallsData,
       },
      ],
     });

     formattedOutputWaitingToConsolidate.push({
      name: `${displayName}`,
      labels: weekLabels, // Week numbers as labels
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingCallsDataToConsolidate,
       },
      ],
     });
    });

    // Assign the output to the dataColumnChartWeekNumber variable
    this.dataColumnChartWeekNumber = this.addMissingPbxElements(
     formattedOutput
    );
    this.dataColumnChartConsWeekNumber = this.consolidateChartData(
     formattedOutput
    );
    this.dataLineChartWeekNumber = this.addMissingPbxElementsLine(
     formattedOutputWaiting
    );
    this.dataLineChartConsWeekNumber = this.consolidateLineChartData(
     formattedOutputWaitingToConsolidate,
     this.consolidateChartData(formattedOutput)
    );
   }
  },
  async setMonthlyChartData(data) {
   const formattedOutput = [];
   const formattedOutputWaiting = [];
   const formattedOutputWaitingToConsolidate = [];

   // Collect all months present in the input data
   const monthSet = new Set();

   // First pass to gather all unique months across all entries
   data?.forEach(item => {
    const details = item.details;
    Object.keys(details).forEach(month => {
     monthSet.add(month); // Add unique month to the set
    });
   });

   // Sort months in ascending order
   const sortedMonths = Array.from(monthSet).sort();

   // Second pass to construct the final output
   data?.forEach(item => {
    const dn = item.dn;
    const details = item.details;

    let displayName = `${dn} - ${
     this.urlType === "queue"
      ? this.pbxMap?.call_queues[dn]?.name
      : this.urlType === "extension"
      ? this.pbxMap?.extensions[dn]
      : dn
    }`;

    const answeredCallsData = [];
    const nonAnsweredCallsData = [];
    const waitingCallsData = [];
    const waitingCallsDataToConsolidate = [];

    // Loop through sorted unique months to make sure we have data for each
    sortedMonths.forEach(month => {
     const monthData = details[month] || {
      inbound_calls: 0,
      answered_calls: 0,
      waiting_time: 0,
     }; // Handle missing months

     answeredCallsData.push(Number(monthData.answered_calls)); // Add Répondus to the data
     waitingCallsData.push(
      Number(monthData.answered_calls)
       ? Number(monthData.waiting_time) / Number(monthData.answered_calls)
       : 0
     ); // Add Répondus to the data
     waitingCallsDataToConsolidate.push(Number(monthData.waiting_time));
     nonAnsweredCallsData.push(
      Number(monthData.inbound_calls) - Number(monthData.answered_calls)
     ); // Calculate Non répondus
    });

    // Construct the entry for current dn
    formattedOutput.push({
     name: `${displayName}`,
     labels: sortedMonths, // Dynamically sorted month numbers as labels
     data: [
      {
       name: "Répondus",
       data: answeredCallsData,
      },
      {
       name: "Non répondus",
       data: nonAnsweredCallsData,
      },
     ],
    });

    formattedOutputWaiting.push({
     name: `${displayName}`,
     labels: sortedMonths, // Week numbers as labels
     data: [
      {
       name: "Temps d'attente moyen",
       data: waitingCallsData,
      },
     ],
    });

    formattedOutputWaitingToConsolidate.push({
     name: `${displayName}`,
     labels: sortedMonths, // Week numbers as labels
     data: [
      {
       name: "Temps d'attente moyen",
       data: waitingCallsDataToConsolidate,
      },
     ],
    });
   });

   // Assign the output to the dataColumnChartMonth variable
   this.dataColumnChartMonth = this.addMissingPbxElements(formattedOutput);
   this.dataColumnChartConsMonth = this.consolidateChartData(formattedOutput);
   this.dataLineChartMonth = this.addMissingPbxElementsLine(
    formattedOutputWaiting
   );
   this.dataLineChartConsMonth = this.consolidateLineChartData(
    formattedOutputWaitingToConsolidate,
    this.consolidateChartData(formattedOutput)
   );
  },
  consolidateChartData(dataColumnCharts) {
   // Initialize a map to store the consolidated data
   const consolidatedDataMap = {
    labels: new Set(),
    answeredCalls: new Map(),
    nonAnsweredCalls: new Map(),
   };

   // Iterate over the input data
   dataColumnCharts.forEach(chart => {
    const { labels, data } = chart;

    // Ensure that 'Répondus' and 'Non répondus' datasets exist
    const answeredData = data.find(d => d.name === "Répondus").data;
    const nonAnsweredData = data.find(d => d.name === "Non répondus").data;

    // Iterate over each label (time period)
    labels.forEach((label, index) => {
     // Add the label to the labels set
     consolidatedDataMap.labels.add(label);

     // Aggregate the answered calls
     if (!consolidatedDataMap.answeredCalls.has(label)) {
      consolidatedDataMap.answeredCalls.set(label, 0);
     }
     consolidatedDataMap.answeredCalls.set(
      label,
      consolidatedDataMap.answeredCalls.get(label) + answeredData[index]
     );

     // Aggregate the non-answered calls
     if (!consolidatedDataMap.nonAnsweredCalls.has(label)) {
      consolidatedDataMap.nonAnsweredCalls.set(label, 0);
     }
     consolidatedDataMap.nonAnsweredCalls.set(
      label,
      consolidatedDataMap.nonAnsweredCalls.get(label) + nonAnsweredData[index]
     );
    });
   });

   // Sort labels (since it's a set)
   const sortedLabels = Array.from(consolidatedDataMap.labels);

   // Construct the final consolidated data
   const consolidatedChartData = {
    labels: sortedLabels,
    name: "Consolidated - All DNs",
    data: [
     {
      name: "Répondus",
      data: sortedLabels.map(
       label => consolidatedDataMap.answeredCalls.get(label) || 0
      ),
     },
     {
      name: "Non répondus",
      data: sortedLabels.map(
       label => consolidatedDataMap.nonAnsweredCalls.get(label) || 0
      ),
     },
    ],
   };

   // Return the consolidated chart data
   return [consolidatedChartData];
  },
  addMissingPbxElements(chartData) {
   this.extensions.length = 0;

   // Assuming this.pbxElements is an array like [809, 802, 803, ...]
   const allHours = chartData.length > 0 ? chartData[0].labels : []; // Get the hour labels from the existing data
   const updatedChartData = [...chartData]; // Clone the original chart data

   // Iterate through pbxElements and add missing dns
   this.pbxElements?.forEach(pbxDn => {
    // Check if the current pbxElement (dn) is already in the chartData
    const existingEntry = updatedChartData.find(entry =>
     entry.name.startsWith(`${pbxDn} -`)
    );

    let displayName = `${pbxDn} - ${
     this.urlType === "queue"
      ? this.pbxMap?.call_queues[pbxDn]?.name
      : this.urlType === "extension"
      ? this.pbxMap?.extensions[pbxDn]
      : pbxDn
    }`;
    this.extensions.push(displayName);
    // If the pbxElement (dn) is missing, add it with empty data
    if (!existingEntry) {
     updatedChartData.push({
      name: displayName,
      labels: allHours, // Use the same labels as the existing entries
      data: [
       {
        name: "Répondus",
        data: [], // Empty data for Répondus
       },
       {
        name: "Non répondus",
        data: [], // Empty data for Non répondus
       },
      ],
     });
    }
   });

   return updatedChartData;
  },
  consolidateLineChartData(dataColumnCharts, consCallsData) {
   console.log("consCallsData", consCallsData);
   // Initialize a map to store the consolidated data
   const consolidatedDataMap = {
    labels: new Set(),
    waitingCalls: new Map(),
    answeredCalls: new Map(),
   };

   // Iterate over the input data
   dataColumnCharts.forEach(chart => {
    const { labels, data } = chart;

    // Ensure that 'Répondus' and 'Non répondus' datasets exist
    const waitingData = data.find(d => d.name === "Temps d'attente moyen")
     ?.data;

    // Iterate over each label (time period)
    labels.forEach((label, index) => {
     // Add the label to the labels set
     consolidatedDataMap.labels.add(label);

     // Aggregate the waiting calls
     if (!consolidatedDataMap.waitingCalls?.has(label)) {
      consolidatedDataMap.waitingCalls.set(label, 0);
     }
     consolidatedDataMap.waitingCalls.set(
      label,
      consolidatedDataMap.waitingCalls.get(label) + waitingData[index]
     );
    });
   });

   consCallsData.forEach(chart => {
    const { labels, data } = chart;

    // Ensure that 'Répondus' and 'Non répondus' datasets exist
    const answeredData = data.find(d => d.name === "Répondus")?.data;

    // Iterate over each label (time period)
    labels.forEach((label, index) => {
     // Aggregate the waiting calls
     if (!consolidatedDataMap.answeredCalls?.has(label)) {
      consolidatedDataMap.answeredCalls.set(label, 0);
     }
     consolidatedDataMap.answeredCalls.set(
      label,
      consolidatedDataMap.answeredCalls.get(label) + answeredData[index]
     );
    });
   });

   console.log(consolidatedDataMap);
   // Sort labels (since it's a set)
   const sortedLabels = Array.from(consolidatedDataMap.labels);

   // Construct the final consolidated data
   const consolidatedChartData = {
    labels: sortedLabels,
    name: "Consolidated - All DNs",
    data: [
     {
      name: "Temps d'attente moyen",
      data: sortedLabels.map(label =>
       consolidatedDataMap.answeredCalls.get(label) > 0
        ? consolidatedDataMap.waitingCalls.get(label) /
          consolidatedDataMap.answeredCalls.get(label)
        : 0
      ),
     },
    ],
   };

   // Return the consolidated chart data
   return [consolidatedChartData];
  },
  addMissingPbxElementsLine(chartData) {
   const allHours = chartData.length > 0 ? chartData[0].labels : []; // Get the hour labels from the existing data
   const updatedChartData = [...chartData]; // Clone the original chart data

   // Iterate through pbxElements and add missing dns
   this.pbxElements?.forEach(pbxDn => {
    // Check if the current pbxElement (dn) is already in the chartData
    const existingEntry = updatedChartData.find(entry =>
     entry.name.startsWith(`${pbxDn} -`)
    );

    let displayName = `${pbxDn} - ${
     this.urlType === "queue"
      ? this.pbxMap?.call_queues[pbxDn]?.name
      : this.urlType === "extension"
      ? this.pbxMap?.extensions[pbxDn]
      : pbxDn
    }`;
    // If the pbxElement (dn) is missing, add it with empty data
    if (!existingEntry) {
     updatedChartData.push({
      name: displayName,
      labels: allHours, // Use the same labels as the existing entries
      data: [
       {
        name: "Temps d'attente moyen",
        data: [], // Empty data for Répondus
       },
      ],
     });
    }
   });

   return updatedChartData;
  },
  async getTimeLabelRange(arr) {
   let sortedArray = arr.sort((a, b) => {
    return a - b;
   });
   let start = ("0" + String(sortedArray[0])).slic;
   let end = ("0" + String(sortedArray[arr.length - 1])).slic;
   try {
    const res = await axios.post(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    return res.data;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
  async getTimeLabelRangeStartAndEnd(start, end) {
   try {
    const res = await axios.post(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    return res.data;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
  async getTimeLabelRangeHourly(arr) {
   let hourlyRange = [];
   let sortedArray = arr.sort((a, b) => {
    return a - b;
   });
   let end = "";
   let start = ("0" + String(sortedArray[0]) + "00").slice(0, 4);
   if (String(sortedArray[arr.length - 1]).length > 1) {
    end = (String(sortedArray[arr.length - 1]) + "00").slice(0, 4);
   } else {
    end = ("0" + String(sortedArray[arr.length - 1]) + "00").slice(0, 4);
   }
   try {
    const res = await axios.post(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    for (let index = 0; index < res.data.length; index++) {
     const element = res.data[index];
     if (element.slice(-2) == "00") {
      hourlyRange.push(element.slice(0, 2));
     }
    }
    return hourlyRange;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
  async getTimeLabelRangeHourlyStartAndEnd(start, end) {
   let hourlyRange = [];
   try {
    const res = await axios.post(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    for (let index = 0; index < res.data.length; index++) {
     const element = res.data[index];
     if (element.slice(-2) == "00") {
      hourlyRange.push(element.slice(0, 2));
     }
    }
    return hourlyRange;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
  filterNoData(data) {
   return data?.filter(res => {
    return res.data[0]?.data?.length > 0 || res.data[1]?.data?.length > 0;
   });
  },
 },
 mounted() {
  // if (!this.periodSelector) {
  //  this.periodSelector = this.currentMonthAndYearForPbx;
  // }
  // if (this.pbxPeriodSelected) this.periodSelector = this.pbxPeriodSelected;
  this.setPeriod();
  if (this.$route.query.tableElement && this.$route.query.period) {
   this.isQueryParam = true;
  }

  this.getButtonSelectedFromQueryPeriod();
 },
 computed: {
  ...mapGetters([
   "account",
   "pbxTimeSelected",
   "hostName",
   "hostContext",
   "hostTimeZone",
   "pbxElementType",
   "pbxPeriodSelected",
  ]),

  currentMonthAndYearForPbx() {
   let options = { month: "short" };
   let month = String("0" + (new Date().getMonth() + 1)).slice(-2);
   let formattedMonth = new Intl.DateTimeFormat("fr-FR", options)
    .format(month)
    .slice(0, -2);
   this.textMonth = formattedMonth;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
 },
 watch: {
  selected_answered_type: function(val) {
   this.setPeriod();
  },
  // is_filtered: function (val) {
  //  switch (this.selected_answered_type) {
  //   case "hour":
  //    this.setHourChartData();
  //   case "day":
  //    this.setDailyChartData();
  //   case "week":
  //    this.setWeeklyChartData();
  //   case "weekNumber":
  //    this.setWeeklyChartByWeekNumber();
  //   case "month":
  //    this.setMonthlyChartData();
  //   default:
  //    return "";
  //  }
  // },
  // periodSelector: function (val) {
  //  this.getDataFromElementForColumn();
  // },
  timeSelected: function(val) {
   //  this.getDataFromElementForColumn(true);
  },
  urlType: function(val) {},
 },
};
</script>

<style>
#page-container {
 position: relative;
 min-height: 97%;
}
#content-wrap {
 padding-bottom: 2rem; /* Footer height */
}
#footer {
 position: absolute;
 bottom: 0;
 width: 100%;
 height: 2.5rem; /* Footer height */
}
</style>
